<template>
  <div>
    <h3 class="page-title">دەرس ئىنكاسى</h3>
    <el-divider></el-divider>
    <div class="rtl-right search-box">
      <el-row :gutter="10">
        <el-col :span="5">
          <el-select v-model="filterForm.status" class="rtl-right" @change="statusChange()" clearable placeholder="ھالىتى">
            <el-option
              v-for="item in statusList"
              :key="item.name"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </div>

    <!-- table-box -->
    <div class="table-box" v-if="pageTags.list">
      <el-table
        :data="tableData" 
        border 
        style="width: 100%" 
        v-loading="loading">
        <el-table-column prop="course.name_ug" width="300" label="دەرس ئىسمى"></el-table-column>
        <el-table-column prop="user.name" width="200" label="ئەزا ئىسمى"></el-table-column>
        <el-table-column prop="content" label="ئىنكاسى"></el-table-column>
        <el-table-column prop="status" width="120" label="ھالەت">
          <template slot-scope="scope">
            <label v-show="scope.row.status==1" style="color:#139d59;">تەستىقتىن ئۆتتى</label>
            <label v-show="scope.row.status==0" style="color:#ff5151;">تەستىقتىن ئۆتمىدى</label>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" width="200" label="قۇرۇلغان ۋاقىت"></el-table-column>
        <el-table-column label="مەشغۇلات" prop="dosome" width="200">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              v-if="pageTags.update"
              @click="handleEdit(scope.$index, scope.row)">تەھرىرلەش </el-button>
            <el-button
              size="mini"
              type="danger"
              v-if="pageTags.delete"
              @click="handleDelete(scope.$index, scope.row)">ئۆچۈرۈش </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        background
        :current-page="current_page"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        layout="sizes,prev, pager, next,total,jumper"
        :total="total">
      </el-pagination> 
    </div>

    <!-- el-dialog -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="35%"
      :before-close="handleClose">
      <div class="rtl-right form-content">
        <el-form label-width="120px" :model="ruleForm" ref="ruleForm">
          <el-form-item label="ھالەت" prop="status" class="radio-form">
            <el-radio v-model="ruleForm.status" label="1">تەستىقتىن ئۆتتى</el-radio>
            <el-radio v-model="ruleForm.status" label="0">تەستىقتىن ئۆتمىدى</el-radio>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" plain @click="submitForm('ruleForm')">مۇقىملاش</el-button>
        <el-button type="warning" plain @click="resetForm('ruleForm')">ئەسلىگە قايتۇرۇش</el-button>
        <el-button type="danger" plain @click="closeDialog('ruleForm')">بىكار قىلىش</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<style lang="scss" scoped>
  .search-box{
    .el-select{
      width: 100%;
    }
    [class*="el-col-"] {
      float: right;
    }
  }
</style>

<script>
  var self;
  export default {
    activated: function() {
      self = this;
      self.getList();
    },
    data() {
      return {
        tableData: [],
        loading: false,
        per_page:10,
        current_page:1,
        last_page:0,
        total:0,
        dialogVisible: false,
        ruleForm: {
          status: "1"
        },
        dialogTitle:"",
        editId:"",
        pageTags:{},
        statusList:[
          {
            name:"تەستىقتىن ئۆتكەن",
            value:"1"
          },
          {
            name:"تەستىقتىن ئۆتمىگەن",
            value:"0"
          }
        ],
        filterForm:{
          status:""
        }
      };
    },
    methods: {
      getList() {
        self.loading = true;
        self.$fetch("admin/course/comment",{
          status:self.filterForm.status,
          page:self.current_page,
          per_page:self.per_page
        }).then(response => {
          if (response.status == 200) {
           self.tableData = response.data.data;
           self.total = response.data.meta.total;
           self.current_page = response.data.meta.current_page;
           self.last_page = response.data.meta.last_page;
          } else{
            console.log(response.message);
          }

          self.loading = false;
          if(JSON.parse(sessionStorage.getItem("curTags"))){
            self.pageTags = JSON.parse(sessionStorage.getItem("curTags"));
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
          self.loading = false;
        });
      },
      handleSizeChange(val) {
        self.per_page = val;
        self.getList();
      },
      handleCurrentChange(val) {
        self.current_page = val;
        self.getList();
      },
      statusChange(){
        self.current_page = 1;
        self.getList();
      },
      handleEdit(index, row) {
        self.dialogTitle = "تەھرىرلەش";
        self.editId = row.id;
        self.ruleForm.status = row.status + '';
        self.dialogVisible = true;
      },
      handleDelete(index, row) {
        self.$confirm("ئۆچۈرۈشنى مۇقۇملامسىز ؟", "ئەسكەرتىش", {
          confirmButtonText: "مۇقىملاش",
          cancelButtonText: "بىكار قىلىش",
          type: "warning"
        }).then(() => {
          self.$remove('admin/course/comment/'+row.id).then((response) => {
            console.log(response);
            if(response.status == 204){
              self.getList();
              self.$message({
                message: "ئۆچۈرۈش تاماملاندى",
                type: "success",
                duration: 1200
              });
            }else{
              console.log(response);
            }
          }).catch(err => {
            console.log('××××××××××××××××');
            console.log(err);
          });

        }).catch(() => {
          self.$message({
            type: "info",
            message: "ئۆچۈرۈش بىكار قىلىندى"
          });
        });
      },
      submitForm(formName) {
        var myData = {
          status: self.ruleForm.status
        }

        self.$put('admin/course/comment/'+self.editId,myData).then((response) => {
          if(response.status == 201){
            self.closeDialog(formName);
            self.getList();
            self.$message({
              message: response.data.message,
              type: "success",
              duration: 1200
            });
          }else{
            self.$message({
              message: response.data.message,
              type: "warning",
              duration: 1500
            });
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      },
      resetForm(formName) {
        self.$refs[formName].resetFields();
        self.ruleForm = {
          status: "1"
        }
      },
      closeDialog(formName) {
        self.resetForm(formName);
        self.dialogVisible = false;
      },
      handleClose(done) {
        done();
        self.resetForm("ruleForm");
      }
    }
  };
</script>
